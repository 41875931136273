import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../components/title";
import TextBox from "../components/textBox";

const OfferPage = () => {
  return (
    <Layout pageTitle="Unsere gemeinsamen Schritte">
      <StaticImage className="w-full" src="../images/offer.jpg" alt="contact" />
      <Title title="Unsere gemeinsamen Schritte" />
      <div className="p-6 flex flex-col space-y-16">
        <TextBox>
          <p>
            Coaching ist eine Beratungsform, die hilft Wesentliches zu erkennen.
            In einem Coaching leuchten wir eine Herausforderung aus, wechseln
            eventuell die Perspektive um einen neuen Blickwinkel zu erhalten.
            Dadurch können gangbare nächste Schritte und ein neuer
            Handlungsspielraum entwickelt werden. Sie sind Experte oder Expertin
            im Prozess, ich bin Impulsgeberin.
          </p>
          <StaticImage
            className="w-full"
            src="../images/coachingTextBox.jpg"
            alt="contact"
          />
        </TextBox>
      </div>
    </Layout>
  );
};

export default OfferPage;
